/*------------------------------------------------------------
  Utilities
------------------------------------------------------------*/

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.text-center {
  text-align: center;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.full-screen {
  width: 100vw;
  height: 100vh;
}

.horizontal-align {
  display: flex;
  justify-content: center;
}
