/*------------------------------------------------------------
  Buttons/Components
------------------------------------------------------------*/

.btn, button, [type=submit] {
  overflow: hidden;
  margin: 10px;
  padding: 12px 12px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: all 60ms ease-in-out;
  transition: all 60ms ease-in-out;
  text-align: center;
  white-space: nowrap;
  text-decoration: none !important;
  text-transform: none;
  text-transform: uppercase;
  border: 0 none;
  border-radius: 4px;
  color: #FFFFFF;
  background: $brand-color;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.3;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.btn:hover, button:hover, [type=submit]:hover {
  -webkit-transition: all 60ms ease;
  transition: all 60ms ease;
  opacity: .85;
}
.btn:active, button:active, [type=submit]:active {
  -webkit-transition: all 60ms ease;
  transition: all 60ms ease;
  -webkit-transform: scale(0.97);
          transform: scale(0.97);
  opacity: .75;
}
.btn:focus, button:focus, [type=submit]:focus {
  outline: none;
}


/* Button Outline */

.btn-outline {
  border: 3px solid #fff;
  color: #fff;
  background: transparent;
  transition: all 0.5s ease;
}

.btn-outline:hover,
.btn-outline:active {
  color: #000;
  background: #fff;
}

.btn-outline-inverted {
  border: 3px solid #000;
  color: #000;
  background: transparent;
  transition: all 0.5s ease;
}

.btn-outline-inverted:hover,
.btn-outline-inverted:active {
  color: #fff;
  background: #000;
}


/* Button Clear */

.btn-clear {
    background: transparent;
    color: gray;
}
